import { useMemo } from "react";
import { useFetchSubRComponentData } from "../../../../helpers/hooks/useFetchSubRComponentData";
import InventoryCustomSubRModal from "../inventoryCustomSubRModal";

interface Props {
  rowData: any,
  onClose():void;
}

function InventoryStockIssues({ rowData, onClose }: Props) {
  const url = useMemo(() => {
    let id = rowData?.stock_issue_no;
    if (!id) {
      return null;
    }
    return `/api/v1/dar/inventory/stock-issues/sub-r/${id}/stock-issues-report?`;
  }, [rowData]);
  const { data, error, isValidating, mutate } = useFetchSubRComponentData(url);

  const contentData = useMemo(() => {
    let config: any = {
      heading: {
        value: "",
      },
      subHeading: {
        label: "",
        value: null,
      },
      overview: {
        details: [],
        summary: { label: "", value: "" },
      },
      table: {
        columns: [],
        data: [],
      },
    };
    if (data && rowData) {
      config.heading.value = rowData?.stock_issue_no;
      config.subHeading.label = "Location: ";
      config.subHeading.value = rowData?.location;
      if (data.length > 0) {
        config.overview.summary = {
          label: "Comments",
          value: data[0].comments,
        };
      }
      config.table.columns = [
        {
          header: "Sr. No",
          accessor: "sr_no",
        },
        {
          header: "Items",
          accessor: "items",
        },
        {
          header: "Cost/ Unit",
          accessor: "cost_per_unit",
        },
        {
          header: "Unit Value",
          accessor: "unit_value",
        },
        {
          header: "Issue Quantity",
          accessor: "issued_quantity",
        },
        {
          header: "Total (Currency)",
          accessor: "total",
        },
      ];
      config.table.data = data.map((d, i) => ({
        sr_no: i + 1,
        items: d.items,
        cost_per_unit: `${d.cost} / ${d.buying_unit}`,
        unit_value: d.unit_value,
        issued_quantity: d.issued_quantity,
        total: d.total,
      }));
    }
    return config;
  }, [data, rowData]);

  return (
    <InventoryCustomSubRModal
      isErrored={Boolean(error)}
      isLoading={isValidating}
      contentData={contentData}
      onClose={onClose}
    />
  );
}

export default InventoryStockIssues;
