import { useEffect, useMemo, useState } from "react";
import useSWR from "swr";
import { fetcher } from "../../state/axios";
import { ENTITY_LOCK_TYPE } from "../../types/visibility";

type Params = {
  /** Interval in seconds between retry checks */
  retryInterval?: number;
  /** Maximum number of retries */
  maxRetries?: number;
  /** Callback to execute when the cluster is up */
  onDataWarehouseUp(): void;
};
/** Custom hook for using functions at deltalake warehouse */
const useDataWarehouseConfigurations = ({
  retryInterval = 5,
  maxRetries = 4,
  onDataWarehouseUp,
}: Params) => {
  const [retry, setRetry] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [isDataWarehouseUp, setIsDataWarehouseUp] = useState(false);
  const [isWarehouseLoading, setIsWarehouseLoading] = useState(true);
  const [isWarehouseLoadError, setIsWarehouseLoadError] = useState(false);

  const { mutate: checkWarehouseStatus, error } = useSWR(
    "/api/v1/databricks/warehouse/start",
    (url) => fetcher(url, { method: "POST" }),
    {
      revalidateOnMount: true,
      shouldRetryOnError: false,
      onSuccess(data, key, config) {
        setIsDataWarehouseUp(data.status);
        if (!data.status) { // if status=false, retry {maxRetries} times with {retryInterval}s interval
          if (retryCount <= maxRetries) {
            setRetry(true);
            setRetryCount((c) => c + 1);
          } else {
            setIsWarehouseLoading(false);
            setIsWarehouseLoadError(true);
          }
        } else {
          onDataWarehouseUp();
          setIsWarehouseLoading(false);
        }
      },
      onError() {
        setIsWarehouseLoading(false);
        setIsWarehouseLoadError(true);
      },
    }
  );

  const vantageLockedStatus = useMemo(() => {
    let isEnabled = false;
    let config = {
      lockType: ENTITY_LOCK_TYPE.UNAUTHORIZED,
      marketingHtmlUrl: "https://sapaad.com/",
    };
    if (error?.response?.status === 423) {
      isEnabled = true;
      config.lockType =
        error?.response?.data?.lock_type ?? ENTITY_LOCK_TYPE.UNAUTHORIZED;
      if (
        error?.response?.data?.lock_type === ENTITY_LOCK_TYPE.MARKETING_MESSAGE
      ) {
        config.marketingHtmlUrl = error?.response?.data?.marketing_html_url;
      }
    }
    return { isEnabled, config };
  }, [error]);

  const restartDataWarehouseCluster = () => {
    setRetry(false);
    setRetryCount(0);
    checkWarehouseStatus();
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (retry && retryCount > 0 && retryCount <= maxRetries) {
      timer = setTimeout(() => {
        checkWarehouseStatus();
        setRetry(false);
      }, retryInterval * 1000);
    }
    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [retry]);

  return {
    isDataWarehouseUp,
    isWarehouseLoading,
    isWarehouseLoadError,
    restartDataWarehouseCluster,
    vantageLockedStatus,
  };
};

export { useDataWarehouseConfigurations };
