import { useEffect, useMemo, useReducer } from "react";
import ReportsLoaderModal from "./reportsLoader";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import TickerBanner from "../../../components/tickerBanner";
import { ReportContext } from "../../../helpers/contexts/reportContext";
import { useFetchReportData } from "../../../helpers/hooks/useFetchReportData";
import { RootState } from "../../../state/store";
import ActiveFiltersSection from "./activeFiltersSection";
import ReportPaginateSection from "./reportPaginateSection";
import { initialState, reducer } from "./reportReducer";
import { paginateUrl, serializeFiltersToUrlParams } from "./utils";
import ReportHeaderSection from "./reportHeaderSection";
import TableComponent from "./tableComponent";

const ReportLayout = () => {
  const [
    {
      apiPath,
      locations,
      dateRange,
      timeRange,
      timezoneOffset,
      reportTitle,
      reportData,
      totalPages,
      pageLimit,
      ftsFilterKeys,
      appliedFtsFilters,
      appliedColumnFilters,
      columnFilterKeys,
      shouldUseEpocForTimeRange,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const routeMatch = useParams();
  const baseApiPath = useMemo(
    () =>
      // TODO - Get validated dashboard and widget url
      routeMatch.dashboard
        ? `/api/v1/dar/${routeMatch.dashboard}/${routeMatch.widget}/report`
        : null,
    [routeMatch]
  );

  let apiEndpoint = useMemo(() => {
    if (apiPath) {
      return paginateUrl(
        serializeFiltersToUrlParams(apiPath, {
          locationFilters: locations,
          dateFilters: dateRange,
          timeFilters: timeRange,
          ftsFilters: appliedFtsFilters,
          columnFilters: appliedColumnFilters,
          timezoneOffset: timezoneOffset,
          shouldUseEpocForTimeRange,
        }),
        pageLimit.startIndex,
        pageLimit.endIndex
      );
    }
    return null;
  }, [
    apiPath,
    locations,
    dateRange,
    pageLimit.startIndex,
    pageLimit.startIndex,
    shouldUseEpocForTimeRange,
    JSON.stringify(appliedFtsFilters),
    JSON.stringify(appliedColumnFilters),
  ]);

  const {
    data: apiData,
    isValidating: isFetchingData,
    data: dataFetchError,
    mutate: retry,
  } = useFetchReportData({
    path: apiEndpoint,
  });

  const { filters, company } = useSelector((state: RootState) => state);
  const companyTimezoneOffset = company?.company_details?.time_zone_offset;

  useEffect(() => {
    if (baseApiPath) {
      dispatch({ type: "RESET_APPLIED_FILTERS", payload: null });
      dispatch({ type: "SET_DATE_RANGE", payload: filters.dates });
      dispatch({ type: "SET_TIME_RANGE", payload: filters.timeRange });
      dispatch({ type: "SET_TIMEZONE_OFFSET", payload: companyTimezoneOffset });
      dispatch({ type: "SET_EPOCH_TOGGLE", payload: company.shouldUseEpocForTimeRange });
      dispatch({ type: "SET_LOCATIONS", payload: filters.locations });
      dispatch({ type: "SET_BASE_API_PATH", payload: baseApiPath });
    }
    return () => {
      // Cleanup
    };
  }, [baseApiPath, filters.dates]);

  useEffect(() => {
    if (apiData) {
      // Load fetched/cached data
      dispatch({ type: "SET_REPORT_DATA", payload: apiData });
    }
  }, [apiData]);

  const handlePageChange = ({ selected }) => {
    dispatch({ type: "CHANGE_SELECTED_PAGE", payload: selected });
  };

  const handleFtsFiltersApply = (selectedFilters) => {
    dispatch({ type: "APPLY_FTS_FILTERS", payload: selectedFilters });
  };

  const handleRemoveFtsFilters = (selectedFilters) => {
    dispatch({ type: "REMOVE_FTS_FILTER", payload: selectedFilters });
  };

  const handleColumnFiltersApply = (selectedFilters) => {
    dispatch({ type: "APPLY_COLUMN_FILTERS", payload: selectedFilters });
  };

  const handleRemoveColumnFilters = (selectedFilters) => {
    dispatch({ type: "REMOVE_COLUMN_FILTERS", payload: selectedFilters });
  };

  const handleResetFilters = () => {
    dispatch({ type: "RESET_APPLIED_FILTERS", payload: null });
  };

  if (!apiData && !reportData && isFetchingData) {
    return <ReportsLoaderModal />;
  }

  if (!apiData && dataFetchError) {
    return (
      <div>
        Oops! Unable to fetch data.{" "}
        <button onClick={() => retry()}>retry</button>
      </div>
    );
  }

  return (
    <ReportContext.Provider
      value={{
        dashboard: routeMatch.dashboard,
        dashboardWidget: routeMatch.widget,
      }}>
      <Wrapper>
        <TickerBanner />
        <ReportHeaderSection title={reportTitle} />
        {reportData ? (
          <>
            {appliedColumnFilters.length > 0 || appliedFtsFilters.length > 0 ? (
              <ActiveFiltersSection
                columnFilters={appliedColumnFilters}
                setAppliedColumnFilters={handleFtsFiltersApply}
                removeColumnFilter={handleRemoveColumnFilters}
                appliedFtsFilters={appliedFtsFilters}
                removeFtsFilter={handleRemoveFtsFilters}
                resetFilters={handleResetFilters}
              />
            ) : null}
            <TableComponent
              tableData={reportData}
              columnFilters={appliedColumnFilters}
              ftsFilterKeys={ftsFilterKeys}
              applyColumnFilters={handleColumnFiltersApply}
              isLoading={isFetchingData}
              setFtsFilters={handleFtsFiltersApply}
              columnFilterKeys={columnFilterKeys}
            />
            {totalPages > 1 ? (
              <ReportPaginateSection
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            ) : null}
          </>
        ) : null}
      </Wrapper>
    </ReportContext.Provider>
  );
};

export default ReportLayout;

const Wrapper = styled.div`
  margin: 50px;
`;
