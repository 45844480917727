import InventoryStockIssues from "../../../components/subReports/inventoryDetails/inventoryStockIssues";
import InventoryStockReceipts from "../../../components/subReports/inventoryDetails/inventoryStockReceipts";
import InventoryStockTakes from "../../../components/subReports/inventoryDetails/inventoryStockTakes";
import NewOrderDetailsModal from "../../../components/subReports/newOrderDetailsModal";

interface ISelectSubReportProps {
  type: string | null;
  rowData: any;
  onClose: () => void;
}

const SelectSubReport = ({type, rowData, onClose}: ISelectSubReportProps) => {
  switch (type) {
    case "OrderDetails":
      return (
        <NewOrderDetailsModal
          selectedOrder={rowData}
          onClose={onClose}
        />
      );
    case "InventoryStockTakes":
      return (
        <InventoryStockTakes
          rowData={rowData}
          onClose={onClose}
        />
      );
    case "InventoryStockIssues":
      return (
        <InventoryStockIssues
          rowData={rowData}
          onClose={onClose}
        />
      );
    case "InventoryStockReceipts":
      return (
        <InventoryStockReceipts
          rowData={rowData}
          onClose={onClose}
        />
      );
    default:
      return <></>;
  }
};

export default SelectSubReport;
