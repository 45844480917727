import { useState } from "react";
import { useAnalyticsDashboardGridLayout } from "../../../helpers/hooks/useAnalyticsDashboardGridLayout";
import styled from "styled-components";
import { Responsive, WidthProvider } from "react-grid-layout";
import {
  breakpoints,
  columns,
} from "../../../helpers/gridLayoutConfigurations";
import WidgetLoader from "./widgetLoader";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";

const AnalyticsWidgetsGrid = ({
  analyticsWidgets,
  dashboard,
  dashboardWidget,
}: {
  analyticsWidgets: { url: string; type: string }[];
  dashboard: string;
  dashboardWidget: string;
}) => {
  const [dummyState, setDummyState] = useState(new Date().getTime());
  const { user_id } = useSelector(
    (state: RootState) => state.company.company_details
  );
  const { gridLayoutConfiguration, saveLayoutToLS } =
    useAnalyticsDashboardGridLayout({
      analyticsWidgets,
      dashboard,
      dashboardWidget,
      userId: user_id,
    });

  const handleOnDragStop = (layout) => {
    saveLayoutToLS(layout);
    setDummyState(new Date().getTime());
  };

  const handleResizeStop = (layout) => {
    saveLayoutToLS(layout);
    setDummyState(new Date().getTime());
  };

  return analyticsWidgets.length && gridLayoutConfiguration ? (
    <GridLayout
      className="grid-layout"
      breakpoints={breakpoints}
      rowHeight={25}
      verticalCompact
      cols={columns}
      layouts={gridLayoutConfiguration}
      containerPadding={{
        lg: [30, 15],
        md: [10, 15],
        sm: [15, 15],
      }}
      isBounded
      margin={[20, 20]}
      onDragStop={handleOnDragStop}
      onResizeStop={handleResizeStop}
      useCSSTransforms={false}
      draggableHandle=".drag-handle">
      {analyticsWidgets.map((widget) => (
        <div key={`${widget.url}*${widget.type}`}>
          <WidgetLoader
            widget={widget}
            dashboard={dashboard}
            dashboardWidget={dashboardWidget}
          />
        </div>
      ))}
    </GridLayout>
  ) : null;
};

export default AnalyticsWidgetsGrid;

const GridLayout = styled(WidthProvider(Responsive))`
  margin: 20px;
  .react-grid-item.react-grid-placeholder {
    background: grey;
    border-radius: 20px;
  }
`;
