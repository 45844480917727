function OverviewSection({ data }) {
  const { summary, details } = data.overview;

  return (
    <div className="overview-section">
      <div className="details-section row">
        {details.map((d) => (
          <div key={d.label} className="detail-group col-md-3">
            <span className="detail-label">{d.label}</span>
            <div className="detail-value">{d.value}</div>
          </div>
        ))}
      </div>
      {summary.label ? (
        <div className="summary-section col-12">
          <span className="summary-label detail-label">{summary.label}</span>
          <div className="summary-placeholder">{summary.value}</div>
        </div>
      ) : null}
    </div>
  );
}

export default OverviewSection;
