import { useEffect, useMemo, useRef, useState } from "react";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import { useMeasure } from "react-use";
import ValueSelectDropdown from "../../valueSelectDropdown";
import BaseChart from "../../baseChart";
import CustomModal from "../../customModal";
import styled from "styled-components";
import { useEChartsOption } from "../../../helpers/hooks/useEchartsOption";

type chartConfig = {
  visual: {
    values: {
      display_name: string;
      accessor: string;
      aggregation_type: "sum" | "average";
      value_formatter: string;
      show_in_tooltip: boolean;
    }[];
    categories: {
      id_accessor: string;
      accessor: string;
      display_name: string;
    }[];
    drill_through: {
      categories: {
        id_accessor: string;
        accessor: string;
        display_name: string;
      }[];
    };
    orientation: "horizontal" | "vertical" | string;
  };
};

const initialConfig = {
  visual: {
    values: [],
    categories: [],
    drill_through: {
      categories: [],
    },
    orientation: "vertical",
  },
};

const formatTooltip = ({ tooltipConfig, chartConfig, selectedValue }) => {
  let categoryAccessor = chartConfig.visual.categories[0].accessor;
  let heading = tooltipConfig.data[categoryAccessor];
  let selectedValueConfig = chartConfig.visual.values.find(
    (d) => d.accessor == selectedValue.accessor
  );
  let values = chartConfig.visual.values.filter(
    (d) => d.accessor !== selectedValue.accessor
  );
  values = [selectedValueConfig, ...values]
    .map((d, i) => {
      let selectedValStyles = "font-weight:600;font-size:14px;";
      if (d.accessor == selectedValue.accessor) {
        selectedValStyles = `font-weight:900;font-size:20px;`;
      }
      return `<span>
        <span style=${selectedValStyles}margin-right:5px;>${d.name}</span>
        <span style='float:right;${selectedValStyles}'>${tooltipConfig.data[
        d.accessor
      ].toLocaleString()} ${d.value_formatter}</span>
      </span>`;
    })
    .join(`<br/>`);
  return `
  <div style='min-width:250px'>
    <h3>${heading}</h3>
      ${values}<br/>
    <div style='text-align:center;'>(Click to view more)</div>
  </div>
  `;
};

const DrillthroughPopup = ({
  data,
  config,
  onClose,
  drillThroughData,
  selectedValue,
}) => {
  const [containerRef, dims] = useMeasure();
  const option = useMemo(() => {
    let chart_configs = { ...initialConfig, ...config };
    let categories = chart_configs.visual.drill_through.categories.map((d) => ({
      type: "ordinal",
      name: d.accessor,
      displayName: d.display_name,
    }));
    let values = chart_configs.visual.values.map((d) => ({
      type: "int",
      name: d.accessor,
      displayName: d.display_name,
    }));
    if (!selectedValue?.accessor) {
      selectedValue.accessor = chart_configs.visual.values[0].accessor;
      selectedValue.displayName = chart_configs.visual.values[0].display_name;
    }
    let seriesConfig: any = [];
    let _data: any[] = [];
    if (chart_configs.visual.drill_through.categories.length > 0) {
      seriesConfig = [
        {
          name: selectedValue.displayName,
          type: "bar",
          sampling: "sum",
          encode: {
            x: categories[0].name,
            y: selectedValue.accessor,
          },
          large: true,
          largeThreshold: 400,
          itemStyle: {
            color: "#58585E",
          },
        },
        
      ];
      // Data Aggregation START
      let _filterIdAccessor =
        chart_configs.visual.drill_through.filter_id_accessor;
      let _categoryIdAccessor =
        chart_configs.visual.drill_through.categories[0].id_accessor;
      let _categoryAccessor =
        chart_configs.visual.drill_through.categories[0].accessor;
      let _valueConfigs = chart_configs.visual.values;
      let _dataCategoryIds: number[] = [
        ...new Set(
          data
            .filter(
              (d) => d[_filterIdAccessor] == drillThroughData[_filterIdAccessor]
            )
            .map((d) => d[_categoryIdAccessor])
        ),
      ];
      _data = _dataCategoryIds.map((cId) => {
        let _catObj = data.find((d) => d[_categoryIdAccessor] == cId);
        let obj = {
          [_categoryIdAccessor]: _catObj[_categoryIdAccessor],
          [_categoryAccessor]: _catObj[_categoryAccessor],
        };
        _valueConfigs.forEach((v) => {
          obj[v.accessor] = data.reduce((acc, d) => {
            if (d[_categoryIdAccessor] === cId) {
              acc += d[v.accessor];
            }
            return acc;
          }, 0);
        });
        return obj;
      });
      // Data Aggregation END
    }
    let legendsConfig = {
      show: false,
    }
    let tooltipConfig = {
      show: true,
      formatter: (params) =>
        formatTooltip({
          tooltipConfig: params,
          chartConfig: {
            ...chart_configs,
            visual: {
              ...chart_configs.visual,
              categories: chart_configs.visual.drill_through.categories,
            },
          },
          selectedValue,
        }),
    };
    let categoryAxisConfig = { type: "category" };
    let valueAxisConfig = {
      type: "value",
    };
    let axisConfig = {
      xAxis: [categoryAxisConfig],
      yAxis: [valueAxisConfig],
    };
    if (config.visual.orientation == "horizontal") {
      axisConfig = {
        xAxis: [valueAxisConfig],
        yAxis: [categoryAxisConfig],
      };
    }
    let gridConfig = {
      left: "50px",
      right: "50px",
    };
    return Object.assign(
      {},
      { legends: legendsConfig },
      { tooltip: tooltipConfig },
      axisConfig,
      { grid: gridConfig },
      {
        dataset: {
          dimensions: [...categories, ...values],
          source: _data,
        },
      },
      { series: seriesConfig }
    );
  }, [data, drillThroughData, config, selectedValue]);

  return (
    <CustomModal show={Boolean(data)} size="lg">
      <CustomModal.Content>
        <CustomModal.CloseButton onClick={onClose} />
        <PopupContentWrapper>
          <div className="header">
            <h2>{config?.visual?.drill_through?.popup_title}</h2>
          </div>
          <div className="content" ref={containerRef}>
            <BaseChart
              chartType="bar"
              renderer="canvas"
              options={option}
              height={dims.height}
              width={dims.width}
            />
          </div>
        </PopupContentWrapper>
      </CustomModal.Content>
    </CustomModal>
  );
};

const PopupContentWrapper = styled.div`
  height: 100%;
  .header {
    position: fixed;
    top: 20px;
  }
  .content {
    margin-top: 30px;
    width: 100%;
    height: 100%;
  }
`;

const BarChart = ({
  selectedValue = {
    displayName: "",
    accessor: "",
  },
  config = initialConfig,
  data = [],
  height,
  width,
  parentChartRef,
  onChartClick = (e) => {},
}: {
  selectedValue: {
    displayName: string;
    accessor: string;
  };
  config: chartConfig;
  data: any[];
  height: number;
  width: number;
  parentChartRef: any;
  onChartClick?: (e: any) => void;
}) => {
  const option = useEChartsOption(() => {
    let chart_configs = { ...initialConfig, ...config };
    let categories = chart_configs.visual.categories.map((d) => ({
      type: "ordinal",
      name: d.accessor,
      displayName: d.display_name,
    }));
    let values = chart_configs.visual.values.map((d) => ({
      type: "int",
      name: d.accessor,
      displayName: d.display_name,
    }));
    if (!selectedValue?.accessor) {
      selectedValue.accessor = chart_configs.visual.values[0].accessor;
      selectedValue.displayName = chart_configs.visual.values[0].display_name;
    }
    let seriesConfig: any = [];
    let _data: any[] = [];
    if (chart_configs.visual.categories.length > 0) {
      seriesConfig = [
        {
          name: selectedValue.displayName,
          type: "bar",
          sampling: "sum",
          encode: {
            x: categories[0].name,
            y: selectedValue.accessor,
          },
          large: true,
          largeThreshold: 400,
          itemStyle: {
            color: "#58585E",
          },
        },
      ];
      // Data Aggregation START
      let _categoryIdAccessor = chart_configs.visual.categories[0].id_accessor;
      let _categoryAccessor = chart_configs.visual.categories[0].accessor;
      let _valueConfigs = chart_configs.visual.values;
      let _dataCategoryIds: number[] = [
        ...new Set(data.map((d) => d[_categoryIdAccessor])),
      ];
      _data = _dataCategoryIds.map((cId) => {
        let _catObj = data.find((d) => d[_categoryIdAccessor] == cId);
        let obj = {
          [_categoryIdAccessor]: _catObj[_categoryIdAccessor],
          [_categoryAccessor]: _catObj[_categoryAccessor],
        };
        _valueConfigs.forEach((v) => {
          obj[v.accessor] = data.reduce((acc, d) => {
            if (d[_categoryIdAccessor] === cId) {
              acc += d[v.accessor];
            }
            return acc;
          }, 0);
        });
        return obj;
      });
      // Data Aggregation END
    }
    let legendsConfig = {
      show: false,
    };
    let tooltipConfig = {
      show: true,
      formatter: (params) =>
        formatTooltip({
          tooltipConfig: params,
          chartConfig: chart_configs,
          selectedValue,
        }),
    };
    let categoryAxisConfig = { type: "category" };
    let valueAxisConfig = {
      type: "value",
    };
    let axisConfig = {
      xAxis: [categoryAxisConfig],
      yAxis: [valueAxisConfig],
    };
    if (config.visual.orientation == "horizontal") {
      axisConfig = {
        xAxis: [valueAxisConfig],
        yAxis: [categoryAxisConfig],
      };
    }
    let gridConfig = {
      left: "50px",
      right: "50px",
    };
    let scrollConfig = [
      {
        type: "inside", // Allows scrolling by mouse/touch
        start: 0,
        end: 30,
      },
    ];
    return Object.assign(
      {},
      { legend: legendsConfig },
      { tooltip: tooltipConfig },
      axisConfig,
      { grid: gridConfig },
      {
        dataset: {
          dimensions: [...categories, ...values],
          source: _data,
        },
      },
      { dataZoom: scrollConfig },
      { series: seriesConfig }
    );
  }, [config, data, selectedValue]);

  return (
    <BaseChart
      ref={parentChartRef}
      chartType="bar"
      options={option}
      height={height}
      width={width}
      renderer="canvas"
    />
  );
};

const BarChartDrillThroughWidget = () => {
  const [widgetRef, contentDimensions] = useMeasure();
  const [chartInstance, setChartinstance] = useState<any>(null);
  const [drillThroughData, setDrillThroughData] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [valueOptions, setValueOptions] = useState([]);
  const widget = useWidgetContext();
  const isWidgetDataEmpty = !widget?.widgetData?.data?.length;

  useEffect(() => {
    // if (!chartInstance) {
    //   setChartinstance(barChart.current?.getEchartsInstance());
    // }
    if (chartInstance) {
      chartInstance.setOption({});
      // Set initial zoom
      setTimeout(() => {
        chartInstance.dispatchAction({
          type: "dataZoom",
          start: 0,
          end: 10,
        });
      }, 1000);
      chartInstance.on("click", function (event) {
        setDrillThroughData(event.data);
      });
    }
    return () => {
      if (chartInstance) {
        chartInstance.off("click");
      }
    };
  }, [chartInstance]);

  useEffect(() => {
    if (widget?.widgetData?.config) {
      let valueConfigs = widget?.widgetData?.config?.visual?.values.map(
        (d) => ({
          key: d.accessor,
          name: d.name,
        })
      );
      let defaultValueConfig = valueConfigs[0];
      setValueOptions(valueConfigs);
      setSelectedValue(defaultValueConfig);
    }
  }, [widget.widgetData]);

  const barChart = useRef(null);

  const barChartRef = (refProps) => {
    barChart.current = refProps;
    if (refProps && !chartInstance) {
      setChartinstance(barChart.current?.getEchartsInstance());
    }
  };

  const handleValueChange = (selValue) => {
    setSelectedValue(selValue);
  };

  const handleDrillThroughPopupClose = () => {
    setDrillThroughData(null);
  };

  const handleChartClick = () => {
    // TODO
  };

  return (
    <Widget ref={widgetRef}>
      <Widget.Header>
        <Widget.Title>{widget?.widgetData?.title}</Widget.Title>
        <Widget.Controls>
          {!isWidgetDataEmpty ? (
            <>
              <Widget.ExtendedAReportCta />
              <ValueSelectDropdown
                options={valueOptions}
                selectedOption={selectedValue}
                onChange={handleValueChange}
              />
            </>
          ) : null}
        </Widget.Controls>
      </Widget.Header>
      <Widget.Content>
        {isWidgetDataEmpty ? (
          <Widget.EmptyData />
        ) : (
          <>
            {drillThroughData ? (
              <DrillthroughPopup
                data={widget.widgetData?.data}
                config={widget.widgetData?.config}
                drillThroughData={drillThroughData}
                selectedValue={selectedValue}
                onClose={handleDrillThroughPopupClose}
              />
            ) : null}
            <BarChart
              config={widget.widgetData?.config}
              data={widget.widgetData?.data}
              height={contentDimensions.height}
              width={contentDimensions.width}
              parentChartRef={barChartRef}
              selectedValue={{
                accessor: selectedValue?.key,
                displayName: selectedValue?.name,
              }}
            />
          </>
        )}
      </Widget.Content>
    </Widget>
  );
};

export default BarChartDrillThroughWidget;
