import { IoMdClose } from "react-icons/io";
import styled from "styled-components";

function ModalHeader({ onClose, data }) {
  const { subHeading, heading } = data;

  return (
    <ModalHeaderWrapper>
      <div className="heading-section">
        <div className="subheading">
          <span className="subheading-label">{subHeading.label}</span>
          <span className="subheading-value">{subHeading.value}</span>
        </div>
        <div className="heading">
          <span className="heading-value">{heading.value}</span>
        </div>
      </div>
      <div className="spacer"></div>
      <div className="modal-close-control">
        <div className="close-btn" onClick={onClose}>
          <IoMdClose fontSize="30px" className="icon" />
        </div>
      </div>
    </ModalHeaderWrapper>
  );
}

export default ModalHeader;

const ModalHeaderWrapper = styled.div`
  background: #1a1f28;
  color: #ffffff;
  height: 80px;
  border-radius: 20px 20px 0px 0px;
  display: flex;
  .heading-section {
    border-radius: 20px 0px 0px 0px;
    height: 100%;
    background-color: #252b37;
    width: 500px;
    padding: 20px 20px 10px 20px;
    .subheading {
      font-size: 11px;
      line-height: 14px;
      font-weight: 400;
      .subheading-label {
        color: #726d6d;
      }
      .subheading-value {
        color: #958888;
      }
    }
    .heading {
      .heading-value {
        font-size: 23px;
        font-weight: 600;
        line-height: 30px;
      }
    }
  }
  .spacer {
    width: 100%;
  }
  .modal-close-control {
    width: 120px;
    height: 100%;
    .close-btn {
      cursor: pointer;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translate(50%, 25px);
      .icon path {
        stroke: #a0a0a0;
      }
    }
  }
`;
