import { useEffect, useState } from "react";
import {
  arrangeWidgets,
  breakpoints,
  createLayoutConfiguration,
  getBreakpoint,
  widgetIdSplitSymbol,
} from "../gridLayoutConfigurations";

interface Params {
  /** Dashboard name */
  dashboard: string;
  /** List of dashboard widgets from data */
  dashboardWidgets: any[];
  /** User specific configuration */
  userId: number;
}
/**
 * React hook that determines layout configuration for widgets at D-level
 * <br/>
 * ### Logic for grid layout in D-level
 * 1. Get layout configuration from localStorage
 * 2. If the layout config does not exist or does not exist for some widgets,
 *    create a new layout based on widget's visual type
 * 3. (Optional) Override layout config for a widget based on properties from widget data
 *    (for bar/line type charts)
 */

const useDashboardGridLayout = ({
  dashboard,
  dashboardWidgets,
  userId = 0,
}: Params) => {
  const [gridLayoutConfiguration, setGridLayoutConfiguration] = useState(null);
  const [dummyState, setDummyState] = useState(new Date().getTime());

  const getLayoutConfigFromLS = (name: string) => {
    let res = localStorage.getItem(name) ?? "null";
    return JSON.parse(res);
  };

  const saveLayoutToLS = (layout) => {
    // Set dummy state to forcefully trigger re-render to refresh widget contents
    setDummyState(new Date().getTime());
    let breakpoint = getBreakpoint(window.innerWidth);
    let existingLayout = JSON.parse(
      localStorage.getItem(`staff_${userId}.layout.dashboard.${dashboard}`) ?? `{}`
    );
    localStorage.setItem(
      `staff_${userId}.layout.dashboard.${dashboard}`,
      JSON.stringify({ ...existingLayout, [breakpoint]: [...layout] })
    );
  };

  useEffect(() => {
    let _gridLayoutConfig = null;
    let currentBreakpoint = getBreakpoint(window.innerWidth);
    if (dashboard && dashboardWidgets && dashboardWidgets.length > 0) {
      // Create a layout from default configuration
      _gridLayoutConfig = getLayoutConfigFromLS(`staff_${userId}.layout.dashboard.${dashboard}`)
      let widgetIdentifiers = dashboardWidgets.map(
        (d) => `${d.url}${widgetIdSplitSymbol}${d.widget.type}`
      );
      let defaultGridLayout = arrangeWidgets(
        createLayoutConfiguration({
          bp: breakpoints,
          items: widgetIdentifiers,
        })
      );
      if (!_gridLayoutConfig) {
        // If no layout configuration found from localStorage, fallback to default layout
        _gridLayoutConfig = defaultGridLayout;
      } else {
        if (
          JSON.stringify(defaultGridLayout[currentBreakpoint]) !==
          JSON.stringify(_gridLayoutConfig[currentBreakpoint])
        ) {
          _gridLayoutConfig = defaultGridLayout;
        }
      }
      setDummyState(new Date().getTime());
      setGridLayoutConfiguration(_gridLayoutConfig);
    }
  }, [userId, dashboard, dashboardWidgets]);

  const shouldLoadGridLayout =
    dashboardWidgets.length && Boolean(gridLayoutConfiguration);

  return { saveLayoutToLS, gridLayoutConfiguration, shouldLoadGridLayout };
};

export { useDashboardGridLayout };
