import * as Dialog from "@radix-ui/react-dialog";
import styled, { keyframes } from "styled-components";
import { Suspense, lazy, useEffect, useMemo } from "react";
import { useExtendedAContext } from "../../hooks/useExtendedAContext";
import { Button } from "react-bootstrap";
import SavedViewsPopup from "../savedViewsPopup";
import CloseButton from "../modalCloseButton";
import SaveCurrentViewPopup from "../saveCurrentViewPopup";
import LoadingView from "../loadingView";
import FetchErrorView from "../fetchErrorView";
import ErrorBoundaryView from "../errorBoundary";
import useMeasure from "react-use/lib/useMeasure";

const PivotTableView = lazy(
  /* webpackChunkName: 'PivotView' */ () => import("../pivotTableView")
);

const ExtendedAReportDialog = () => {
  const {
    showReport,
    hideReport,
    extendedAData,
    refetchReportData,
    getPivotTableInstance,
    views,
    isFetchingReportData,
    isFetchReportError,
    reportDownloadProgress,
    externalLinkChunkError,
  } = useExtendedAContext();
  const {
    showSavedViews,
    isShowSavedViews,
    showSaveCurrentView,
    isShowSaveCurrentView,
  } = views;
  const [containerRef, dims] = useMeasure();

  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  useEffect(() => {
    if (showReport) {
      // Fetch only if modal is shown
      refetchReportData();
    }
  }, [showReport]);

  const handleCloseModal = () => {
    hideReport();
    document.body.style.overflow = "";
  };

  const showCalculatedField = () => {
    const pivotTableInstance = getPivotTableInstance();
    pivotTableInstance?.createCalculatedFieldDialog();
  };

  const isReportDataLoading = useMemo(() => {
    return (
      isFetchingReportData ||
      ["downloading", "parsing", "complete"].includes(
        reportDownloadProgress.state
      )
    );
  }, [isFetchingReportData, reportDownloadProgress.state]);

  return (
    <>
      {isShowSaveCurrentView ? <SaveCurrentViewPopup /> : null}
      {isShowSavedViews ? <SavedViewsPopup /> : null}
      <Dialog.Root key="report-dialog" open={showReport} modal={false}>
        <Dialog.Portal container={document.body} />
        <Wrapper>
          <Dialog.Overlay className="dialog-overlay" forceMount={showReport} />
          <Dialog.Content className="dialog-content">
            <CloseButton onClick={handleCloseModal} />
            {isReportDataLoading ? (
              <LoadingView
                loadingText="Loading Report..."
                downloadProgress={reportDownloadProgress}
              />
            ) : (
              <>
                {extendedAData ? (
                  <div className="report-container" ref={containerRef}>
                    <div className="header">
                      <h3 className="report-title">
                        {extendedAData?.extended_data?.config?.report_title ??
                          ""}
                      </h3>
                      <Button
                        className="calculated-field-cta"
                        onClick={showCalculatedField}
                      >
                        Calculated Field
                      </Button>
                      <Button className="saved-views" onClick={showSavedViews}>
                        Saved Views
                      </Button>
                      <Button
                        className="save-current-view"
                        onClick={showSaveCurrentView}
                      >
                        Save Current view
                      </Button>
                    </div>
                    <ErrorBoundaryView>
                      <Suspense
                        fallback={
                          <LoadingView loadingText="Loading Pivot Table" />
                        }
                      >
                        <PivotTableView
                          data={extendedAData?.extended_data}
                          height={dims.height === 0 ? 660 : dims.height - 50}
                          width={dims.width === 0 ? 1308 : dims.width}
                        />
                      </Suspense>
                    </ErrorBoundaryView>
                  </div>
                ) : null}
                {isFetchReportError ? (
                  <FetchErrorView
                    errorType={externalLinkChunkError ? "chunkError" : "fetch"}
                    refreshData={refetchReportData}
                  />
                ) : null}
              </>
            )}
          </Dialog.Content>
        </Wrapper>
      </Dialog.Root>
    </>
  );
};

const overlayShow = keyframes({
  "0%": { opacity: 0 },
  "100%": { opacity: 1 },
});

const contentShow = keyframes({
  "0%": { opacity: 0, transform: "translate(-50%, -48%) scale(.96)" },
  "100%": { opacity: 1, transform: "translate(-50%, -50%) scale(1)" },
});

const Wrapper = styled.div`
  .dialog-overlay {
    background-color: rgb(0 0 0 / 44%);
    position: fixed;
    inset: 0;
    animation: ${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1);
  }
  .dialog-content {
    background-color: ${({ theme }) => theme.pivot_table.background_color};
    border-radius: 6px;
    box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
      hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90vw;
    max-width: 90%;
    height: 80%;
    padding: 25px;
    border-radius: 20px;
    border: 1px solid ${({ theme }) => theme.pivot_table.table_props.border_color};
    animation: ${contentShow} 600ms cubic-bezier(0.16, 1, 0.3, 1);
    z-index: 1500;
    .close-icon {
      cursor: pointer;
      position: absolute;
      right: -23px;
      top: -18px;
      border-radius: 20px;
      width: 50px;
      height: 50px;
      background-color: #fff;
      > svg {
        fill: "#212121" !important;
      }
    }
    .report-container {
      display: flex;
      flex-flow: column;
      height: 100%;
      width: 100%;
      .header {
        position: fixed;
        display: flex;
        flex-flow: row;
        width: 100%;
        padding-right: 50px;
        .report-title {
          flex: 1;
        }
        .btn {
          margin: 5px;
          outline: none;
          border: none;
          &.saved-views {
            background-color: #2b7bc5;
          }
          &.save-current-view {
            background-color: #565656;
          }
        }
      }
    }
  }
`;

export default ExtendedAReportDialog;
