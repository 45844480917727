import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import styled from "styled-components";

function TableData({ contextData }) {
  const { columns, data } = contextData.table;
  const columnHelper = createColumnHelper();
  const tableColumns = columns.map((c) =>
    columnHelper.accessor(c.accessor, {
      header: c.header,
      cell: (info) => info.getValue(),
      meta: c,
    })
  );

  const table = useReactTable({
    columns: tableColumns,
    data: data,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Wrapper className="table-data-section">
      <table>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getCoreRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Wrapper>
  );
}

export default TableData;

const Wrapper = styled.div`
  overflow: scroll;
  table {
    height: 100%;
    width: 100%;
    border-spacing: 0;
    overflow-x: scroll;
    background-color: ${({ theme }) => theme.widget.background};
    > thead {
      height: 40px;
      > tr {
        > th {
          margin-left: 20px;
          font-size: 16px;
          color: ${({ theme }) => theme.text};
          font-weight: 700;
          white-space: nowrap;
          position: relative;
          padding: 0 0.5rem;
        }
      }
    }
    > tbody {
      border-top: 1px solid #e4e4e4;
      > tr {
        > td {
          font-size: 15px;
          font-weight: 400;
          line-height: 21.48px;
          white-space: nowrap;
          padding: 0 0.5rem;
        }
        height: 49px;
        &:nth-child(odd) {
          background-color: ${({ theme }) => theme.widget_group.background};
        }
      }
    }
  }
`;
