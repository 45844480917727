import { createGlobalStyle } from "styled-components";
import { calculatedFieldStyles } from "../features/extendedAnalytics/helpers/overrideStyles";

export const GlobalStyles = createGlobalStyle`

body {
  background: ${({ theme }) => theme.body};
  color:${({ theme }) => theme.text};
  font-family: ibm-plex-sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 23px;
  transition: all 0.25s linear;
  // Pivot table Calculated field styles
  ${calculatedFieldStyles}}
}

.btn {
  box-shadow: none;
  &-primary {
    background-color: ${({ theme }) => theme.button.primary_color};
    &:focus {
      color: #ffffff;
      background-color: ${({ theme }) => theme.button.primary_color};
      border-color: ${({ theme }) => theme.button.primary_border_color};
      box-shadow: none;
      outline: none;
    }
    &:hover,
    &:not(:disabled):not(.disabled):active {
      color:#ffffff;
      background-color: ${({ theme }) => theme.button.primary_color};
      border-color: ${({ theme }) => theme.button.primary_border_color};
    }
  }
}
.react-grid-item {
  &:hover {
    .resizer {
      opacity: 1;
    }
    .drag-handle {
      display: block;
      cursor: grab;
      &:active {
        cursor: grabbing;
      }
    }
    .react-resizable-handle {
      opacity: 0;
    }
  }
}
.resizer {
  opacity: 0;
}
.drag-handle {
  display: none;
  position: absolute;
  top: -17px;
  left: -9px;
  z-index: 1;
  > svg {
    fill: #5f5f5f;
    background: #D2D2D2;
    width: 30px;
    height: 30px;
    padding: 3px;
    border-radius: 25px;
  }
}
.react-resizable-handle {
  opacity: 0;
}

.show > .dropdown-toggle {
  background-color: ${({ theme }) => theme.button.primary_color};
  border-color: ${({ theme }) => theme.button.primary_border_color};
}
.dropdown-menu {
  color: ${({ theme }) => theme.text};
  background-color: ${({ theme }) => theme.dropdown.background_color};
}
.react-datepicker {
  font-family: ibm-plex-sans, Helvetica Neue, Helvetica, Arial, sans-serif;
}
.echarts-for-react {
  > div > svg > rect {
    fill: rgb(255,255,255,0);
  }
}
.modal {
  .custom {
    .modal-content {
      border-radius: 0 !important;
      box-sizing: border-box;
      border: 0;
      background: ${({ theme }) => theme.modal.background};
    }

    &.curved,
    &.curved-wide {
      .modal-content {
        border-radius: 20px !important;
        box-sizing: border-box;
        border: 0;
      }
    }

    &.curved-wide {
      width: 80vw;
      max-width: none !important;
    }

  }
  .curved-wide-report {
    max-width: 90vw;
    height: 90vh;
    .modal-content {
      width: 100%;
      height: 100%;
      max-width: none !important;
      border-radius: 25px !important;
    }
  }

  .close-modal-btn {
    cursor: pointer;
    z-index: 1;
    position: absolute;
    top: -20px;
    right: -20px;
    border-radius: 20px;
    width: 50px;
    height: 50px;
    background-color: #fff;
    > svg {
      fill: "#212121" !important;
    }

    .modal-body {
      overflow-y: scroll;
    }
  }
  .company-switch-dialog {
    .modal-content {
      background-color: ${({ theme }) => theme.modal.background};
      border-radius: 30px;
    }
  }

  .sub-r-custom-inventory-modal {
    max-width: 1004px;
    max-height: 731px;
    .modal-content {
      width: 100%;
      height: 100%;
      max-width: none !important;
      border-radius: 20px !important;
      border: 1px solid ${({ theme }) => theme.border_color};
    }
  }
}
.company-name-popover {
  max-width: 600px;
  .popover-body {
    text-align: center;
  }
}
`;
