import { Modal } from "react-bootstrap";
import ModalHeader from "./modalHeader";
import ModalContent from "./modalContent";
import SubReportModalErrorBoundary from "../../../../containers/layout/report/errorBoundary";

interface Props {
  isLoading: boolean;
  isErrored: boolean;
  contentData: any;
  onClose(): void;
}

function InventoryCustomSubRModal({
  isLoading,
  isErrored,
  contentData,
  onClose,
}: Props) {
  return (
    <SubReportModalErrorBoundary>
      <Modal
        show={true}
        dialogClassName="custom sub-r-custom-inventory-modal"
        onHide={onClose}
        size="lg"
        centered>
        <ModalHeader data={contentData} onClose={onClose} />
        <ModalContent
          isErrored={isErrored}
          isLoading={isLoading}
          data={contentData}
        />
      </Modal>
    </SubReportModalErrorBoundary>
  );
}

export default InventoryCustomSubRModal;
