import { useEffect, useState } from "react";
import {
  arrangeWidgets,
  breakpoints,
  createLayoutConfiguration,
  getBreakpoint,
  getLayoutConfigFromLS,
  widgetIdSplitSymbol,
} from "../gridLayoutConfigurations";

interface Params {
  /** Dashboard name; eg. `sales` */
  dashboard: string;
  /**
   * Dashboard widget;
   * eg. `orders_and_sales` unders `sales` dashboard
   * */
  dashboardWidget: string;
  /** List of analytics widgets */
  analyticsWidgets: { url: string; type: string }[];
  userId: number;
}

/**
 * React hook that determines layout configuration for widgets at A-level
 * <br/>
 * ### Logic for grid layout in A-level
 * 1. Get layout configuration from localStorage
 * 2. If the layout config does not exist or does not exist for some widgets,
 *    create a new layout based on widget's visual type
 * 3. Override layout config for a widget based on properties from widget data
 *    (for bar/line type charts)
 */

const useAnalyticsDashboardGridLayout = ({
  analyticsWidgets,
  dashboard,
  dashboardWidget,
  userId = 0,
}: Params) => {
  const [gridLayoutConfiguration, setGridLayoutConfiguration] = useState(null);

  const saveLayoutToLS = (layout) => {
    let breakpoint = getBreakpoint(window.innerWidth);
    let existingLayout = JSON.parse(localStorage.getItem(`staff_${userId}.layout.${dashboard}.${dashboardWidget}`)) || {};
    localStorage.setItem(
      `staff_${userId}.layout.${dashboard}.${dashboardWidget}`,
      JSON.stringify({ ...existingLayout, [breakpoint]: [...layout] })
    );
  };

  const getLayoutConfig = (dashboard, dashboardWidget) => {
    let analyticsLayoutConfig = getLayoutConfigFromLS(
      `staff_${userId}.layout.${dashboard}.${dashboardWidget}`
    );
    return analyticsLayoutConfig;
  };

  useEffect(() => {
    let _gridLayoutConfig = null;
    let currentBreakpoint = getBreakpoint(window.innerWidth);
    if (dashboard && dashboardWidget && analyticsWidgets.length > 0) {
      let widgetIdentifiers = analyticsWidgets.map(
        (d) => `${d.url}${widgetIdSplitSymbol}${d.type}`
      );
      let defaultGridLayout = arrangeWidgets(
        createLayoutConfiguration({
          bp: breakpoints,
          items: widgetIdentifiers,
        })
      );
      if(!_gridLayoutConfig) {
        // If no layout configuration found from localStorage, fallback to default layout
        _gridLayoutConfig = defaultGridLayout;
      } else {
        if (
          JSON.stringify(defaultGridLayout[currentBreakpoint]) !==
          JSON.stringify(_gridLayoutConfig[currentBreakpoint])
        ) {
          _gridLayoutConfig = defaultGridLayout;
        }
      }
      setGridLayoutConfiguration(_gridLayoutConfig);
    }
  }, [analyticsWidgets, dashboard, dashboardWidget]);

  useEffect(() => {
    let gridConfig: null = null;
    if (analyticsWidgets.length) {
      // Get layout from localStorage
      gridConfig = getLayoutConfig(dashboard, dashboardWidget);
      let currentBreakpoint = getBreakpoint(window.innerWidth);
      let widgetIdentifiers = analyticsWidgets.map(
        (d) => `${d.url}${widgetIdSplitSymbol}${d.type}`
      );
      let defaultGridLayout = arrangeWidgets(
        createLayoutConfiguration({
          bp: breakpoints,
          items: widgetIdentifiers,
        })
      );
      if (!gridConfig) {
        // If layout config does not exist create a new layout
        gridConfig = defaultGridLayout;
      } else {
        let identifiersFromlayout = gridConfig[currentBreakpoint].map(
          (d) => d.i
        );
        if (
          JSON.stringify(identifiersFromlayout) !==
          JSON.stringify(widgetIdentifiers)
        ) {
          gridConfig = defaultGridLayout;
        }
      }
      setGridLayoutConfiguration(gridConfig);
    }
  }, [JSON.stringify(analyticsWidgets)]);

  return { gridLayoutConfiguration, saveLayoutToLS };
};

export { useAnalyticsDashboardGridLayout };
