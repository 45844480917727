import styled from "styled-components";
import OverviewSection from "../overviewSection";
import TableData from "../tableData";
import LoadingAnimation from "../../../../loadingAnimation";

function ModalContent({ data, isLoading, isErrored }) {
  const { summary, details } = data.overview;

  if (isLoading) {
    return (
      <ModalContentWrapper>
        <div className="loading-section">
          <LoadingAnimation />
          <span>Loading...</span>
        </div>
      </ModalContentWrapper>
    );
  }

  if (isErrored) {
    return (
      <ModalContentWrapper>
        <div className="error-section">
          <span>Unable to fetch data. Please try again</span>
        </div>
      </ModalContentWrapper>
    );
  }

  return (
    <ModalContentWrapper>
      {summary.label || details.length > 0 ? (
        <OverviewSection data={data} />
      ) : null}
      <TableData contextData={data} />
    </ModalContentWrapper>
  );
}

export default ModalContent;

const ModalContentWrapper = styled.div`
  .loading-section,
  .error-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    height: 100%;
  }
  height: 600px;
  background-color: ${({ theme }) => theme.widget.background};
  border-radius: 0px 0px 20px 20px;
  overflow-y: scroll;
  .detail-label {
    font-size: 14px;
    color: ${({ theme }) => theme.modal.text};
  }
  .detail-value {
    font-size: 18px;
    color: ${({ theme }) => theme.text};
  }
  .overview-section {
    margin: 10px;
    padding: 10px;
    background-color: ${({ theme }) => theme.body};
    .summary-section {
      margin-top: 10px;
      width: 100%;
      .summary-placeholder {
        padding: 10px;
        height: 80px;
        background-color: ${({ theme }) => theme.widget_group.background};
      }
    }
  }
  .table-data-section {
    margin: 10px;
  }
`;
