import { useMemo } from "react";
import InventoryCustomSubRModal from "../inventoryCustomSubRModal";
import { useFetchSubRComponentData } from "../../../../helpers/hooks/useFetchSubRComponentData";

function InventoryStockTakes({ rowData, onClose }: any) {
  const url = useMemo(() => {
    let id = rowData?.ref_no;
    if (!id) {
      return null;
    }
    return `/api/v1/dar/inventory/stock-take-reconciliation/sub-r/${id}/stock-takes-report?`;
  }, [rowData]);
  const { data, error, isValidating, mutate } = useFetchSubRComponentData(url);

  const contentData = useMemo(() => {
    let config: any = {
      heading: {
        value: "",
      },
      subHeading: {
        label: "",
        value: null,
      },
      overview: {
        details: [],
        summary: { label: "", value: "" },
      },
      table: {
        columns: [],
        data: [],
      },
    };
    if (data && rowData) {
      config.heading.value = rowData?.ref_no;
      config.subHeading.label = "Location: ";
      config.subHeading.value = rowData?.location;
      if (data.length > 0) {
        config.overview.details = [
          {
            label: "Date",
            value: data[0].date,
          },
          {
            label: "Stock Take Number / Reference",
            value: data[0].ref_no,
          },
        ];
        config.overview.summary = {
          label: "Comments",
          value: data[0].comments,
        };
      }
      config.table.columns = [
        {
          header: "Sr. No",
          accessor: "sr_no",
        },
        {
          header: "Inventory Items",
          accessor: "inventory_items",
        },
        {
          header: "Closing Stock",
          accessor: "closing_stock",
        },
        {
          header: "Closing Stock Value",
          accessor: "closing_stock_value",
        },
        {
          header: "Counted Stock",
          accessor: "counted_stock",
        },
        {
          header: "Counted Stock Value",
          accessor: "counted_stock_value",
        },
        {
          header: "Stock Difference",
          accessor: "stock_diff",
        },
        {
          header: "Cost Difference",
          accessor: "cost_diff",
        },
      ];
      config.table.data = data.map((d, i) => ({
        sr_no: i + 1,
        inventory_items: d.inventory_items,
        closing_stock: d.closing_stock,
        closing_stock_value: d.closing_stock_value,
        counted_stock: d.counted_stock,
        counted_stock_value: d.counted_stock_value,
        stock_diff: d.stock_diff,
        cost_diff: d.cost_diff,
      }));
    }
    return config;
  }, [data, rowData]);
  return (
    <InventoryCustomSubRModal
      isErrored={Boolean(error)}
      isLoading={isValidating}
      contentData={contentData}
      onClose={onClose}
    />
  );
}

export default InventoryStockTakes;
