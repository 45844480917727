import { forwardRef, useMemo } from "react";
import styled from "styled-components";
import ReactEcharts from "echarts-for-react";
import type { ECharts, EChartsOption } from "echarts";
import type {
  BaseChartConfigTypes,
  EChartChartReadyHandler,
  EChartClickHandler,
  EChartDataZoomHandler,
  EChartFinishedHandler,
  EChartMouseOutHandler,
  EChartMouseOverHandler,
  EChartRenderedHandler,
  EChartRestoreHandler,
  ReactEchartsRef,
} from "../../types/echarts";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

interface IBaseChartProps {
  forwardedRef?: React.Ref<any>;
  autoHeightResize?: boolean;
  notMerge?: boolean;
  lazyUpdate?: boolean;
  echartsTheme?: any;
  onChartReady?: EChartChartReadyHandler;
  onClick?: EChartClickHandler;
  onLegendSelectChanged?: any;
  style?: any;
  height: string | number;
  width: string | number;
  options: EChartsOption | unknown;
  chartType: BaseChartConfigTypes;
  renderer?: "svg" | "canvas" | undefined;
}

const BaseChartUnwrapped = ({
  autoHeightResize = false,
  notMerge = false,
  lazyUpdate = false,
  options = {},
  height = 200,
  width,
  forwardedRef,
  onClick,
  style = {},
  onChartReady,
  chartType,
  renderer = "svg",
}: IBaseChartProps) => {
  const { theme } = useSelector((state: RootState) => state);

  const eventsMap = useMemo(
    () => ({
      click: (props: any, instance: any) => {
        handleClick(props, instance);
        onClick?.(props, instance);
      },
    }),
    [onClick]
  );

  const handleClick = (clickSeries: any, instance: ECharts) => {
    if (clickSeries.data) {
      clickSeries.data.onClick?.(clickSeries, instance);
    }
  };

  const chartStyles = {
    height: autoHeightResize ? "100%" : height,
    width: width,
    ...style,
  };

  const chartOption: EChartsOption = {
    ...options,
    legend: {
      ...options.legend,
      icon: "circle",
    },
    textStyle: {
      fontFamily: "ibm-plex-sans, Helvetica Neue, Helvetica, Arial, sans-serif",
    },
    color: [
      "#2DB6F5",
      "#39C86A",
      "#F5C324",
      "#FF8A65",
      "#E289F2",
      "#FF6B6B",
      "#54A0FF",
      "#0D8A6A",
      "#FF9F43",
      "#1C5085",
      "#58585E",
      "#607D8B",
      "#BE4242",
      "#995F28",
    ],
  };
  return (
    <ChartContainer>
      <ReactEcharts
        ref={forwardedRef}
        notMerge={notMerge}
        lazyUpdate={lazyUpdate}
        theme={theme.mode}
        onChartReady={onChartReady}
        onEvents={eventsMap}
        style={chartStyles}
        opts={{
          height: autoHeightResize ? undefined : height,
          renderer,
        }}
        option={chartOption}
      />
    </ChartContainer>
  );
};

const BaseChart = forwardRef<ReactEchartsRef, IBaseChartProps>((props, ref) => (
  <BaseChartUnwrapped forwardedRef={ref} {...props} />
));

export default BaseChart;

const ChartContainer = styled.div`
  display: inline-block;
  // TODO: Add chart global styles here. eg. color schemes, etc
`;
