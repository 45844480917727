import { useEffect, useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addWidgetToFilterList } from "../../../state/features/analytics/actions";
import TabularList from "./listTable";
import Widget from "../../baseWidget";
import { useWidgetContext } from "../../../helpers/hooks/useWidgetContext";
import { RootState } from "../../../state/store";
import styled from "styled-components";
import { TbSortAscendingNumbers, TbSortDescendingNumbers } from "react-icons/tb";
import { useMeasure } from "react-use";

const SortControl = ({ sortAscending, handleSortModeSelection }) => {
  return (
    <SortButton onClick={handleSortModeSelection}>
      {sortAscending ? (
        <TbSortAscendingNumbers />
      ) : (
        <TbSortDescendingNumbers width={"14px"} />
      )}
    </SortButton>
  );
};

const SortButton = styled.div`
  box-sizing: border-box;
  height: 35px;
  width: 35px;
  border: 2px solid #58585e;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 2px 8px;
  padding: 0px;
  align-items: center;
`;

const ListWidget = () => {
  const [widgetData, setWidgetData] = useState<any>({});
  const [sortAscending, setSortAscending] = useState(false);
  const [widgetFilterOption, setWidgetFilterOption] = useState("");
  const [widgetDataCopy, setWidgetDataCopy] = useState({});
  const [widgetRef, contentDimensions] = useMeasure();
  const [widgetHeaderRef, headerDimensions] = useMeasure();
  const [rowLimit, setRowLimit] = useState(10);

  const widget = useWidgetContext();
  const dispatch = useDispatch();

  const { widgets: widgetFilters } = useSelector(
    (state: RootState) => state.filters
  );

  useEffect(() => {
    if (widget.widgetData.widget_data) {
      setWidgetData(filterWidgetData("10", sortWidgetData(widget.widgetData)));
      setWidgetFilterOption("10");
      setWidgetDataCopy(widget.widgetData);
      const widgetFilterExists = widgetFilters.find(
        (i) => i.key == widget?.widgetFilterConfig?.widgetFilterKey
      );
      if (widget.widgetFilterConfig?.enableFilters && !widgetFilterExists) {
        dispatch(
          addWidgetToFilterList({
            name: widget.widgetFilterConfig.filterTitle,
            filterKey: widget.widgetFilterConfig.widgetFilterKey,
            options: widget.widgetData.widget_data.data.rows.map((d) => ({
              id: d[widget.widgetFilterConfig.widgetFilterKey],
              name: d[widget.widgetData.widget_data.config.filter_by_accessor],
            })),
          })
        );
      }
    }
  }, [widget.widgetData]);

  const tableData = useMemo(() => {
    if (widget.widgetData.widget_data) {
      const sortAccessor =
        widget.widgetData.widget_data.config.sort_by_column_accessor;
      return {
        ...widget.widgetData.widget_data,
        data: {
          ...widget.widgetData.widget_data.data,
          rows: widget.widgetData.widget_data.data.rows
            .sort((a, b) =>
              sortAscending
                ? a[sortAccessor] - b[sortAccessor]
                : b[sortAccessor] - a[sortAccessor]
            )
            .filter((d, i) => i < rowLimit),
        },
      };
    }
    return null;
  }, [sortAscending, rowLimit, widget.widgetData]);

  const handleWidgetDataSorting = (sortAscending) => {
    setSortAscending(sortAscending);
    let sortedData = filterWidgetData(
      widgetFilterOption,
      sortWidgetData(widgetDataCopy, sortAscending)
    );
    setWidgetData(sortedData);
  };

  const sortWidgetData = (widgetData, ascending = sortAscending) => {
    let accessorSortingKey =
      widgetData.widget_data.config.sort_by_column_accessor;
    let updatedRowData = widgetData.widget_data.data.rows.sort((a, b) => {
      if (!ascending) {
        return b[accessorSortingKey] - a[accessorSortingKey];
      }
      return a[accessorSortingKey] - b[accessorSortingKey];
    });
    return {
      ...widgetData,
      widget_data: {
        ...widgetData.widget_data,
        data: { ...widgetData.widget_data.data, rows: updatedRowData },
      },
    };
  };

  const handleWidgetDataFiltering = (option) => {
    setWidgetFilterOption(option);
    setWidgetData(filterWidgetData(option, sortWidgetData(widgetDataCopy)));
  };

  const filterWidgetData = (option, widgetData) => {
    let updatedRowData = [...widgetData.widget_data.data.rows];
    if (option === "All") {
      updatedRowData = [...widgetData.widget_data.data.rows];
    } else {
      updatedRowData = widgetData.widget_data.data.rows.filter(
        (dat, idx) => idx <= parseInt(option) - 1
      );
    }
    return {
      ...widgetData,
      widget_data: {
        ...widgetData.widget_data,
        data: {
          ...widgetData.widget_data.data,
          rows: updatedRowData,
        },
      },
    };
  };

  const changeSortMode = () => {
    setSortAscending((s) => !s);
  };

  const tableRowData = useMemo(() => {
    if (widgetData?.widget_data) {
      return widgetData.widget_data.data.rows;
    }
    return [];
  }, [JSON.stringify(widgetData)]);

  return (
    <Widget ref={widgetRef}>
      <div className="widget-header" ref={widgetHeaderRef}>
        <Widget.Title>{widgetData.title}</Widget.Title>
        <Widget.Controls>
          <Widget.ExtendedAReportCta />
          <SortControl
            sortAscending={sortAscending}
            handleSortModeSelection={changeSortMode}
          />
          <Widget.AiSuggestionCta />
        </Widget.Controls>
      </div>
      <Widget.Content>
        {tableRowData.length > 0 ? (
          <TabularList
            data={tableData}
            width={contentDimensions.width}
            height={contentDimensions.height - headerDimensions.height}
          />
        ) : (
          <Widget.EmptyData
            width={contentDimensions.width}
            height={contentDimensions.height - headerDimensions.height}
          />
        )}
      </Widget.Content>
    </Widget>
  );
};

export default ListWidget;
